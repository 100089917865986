@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');

body {
  height: 100% !important; /* Set the height of the root elements to 100% */
  margin: 0; /* Remove any default margins */
  padding: 0; /* Remove any default padding */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #031827 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../public/fonts/SFUIText-Bold.eot");
  src: url("../public/fonts/SFUIText-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/SFUIText-Bold.woff2") format("woff2"),
    url("../public/fonts/SFUIText-Bold.woff") format("woff"),
    url("../public/fonts/SFUIText-Bold.ttf") format("truetype"),
    url("../public/fonts/SFUIText-Bold.svg#SFUIText-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../public/fonts/SFUIText-Medium.eot");
  src: url("../public/fonts/SFUIText-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/SFUIText-Medium.woff2") format("woff2"),
    url("../public/fonts/SFUIText-Medium.woff") format("woff"),
    url("../public/fonts/SFUIText-Medium.ttf") format("truetype"),
    url("../public/fonts/SFUIText-Medium.svg#SFUIText-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../public/fonts/SFUIText-Semibold.eot");
  src: url("../public/fonts/SFUIText-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/SFUIText-Semibold.woff2") format("woff2"),
    url("../public/fonts/SFUIText-Semibold.woff") format("woff"),
    url("../public/fonts/SFUIText-Semibold.ttf") format("truetype"),
    url("../public/fonts/SFUIText-Semibold.svg#SFUIText-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Text";
  src: url("../public/fonts/SFUIText-Regular.eot");
  src: url("../public/fonts/SFUIText-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/SFUIText-Regular.woff2") format("woff2"),
    url("../public/fonts/SFUIText-Regular.woff") format("woff"),
    url("../public/fonts/SFUIText-Regular.ttf") format("truetype"),
    url("../public/fonts/SFUIText-Regular.svg#SFUIText-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* React Calender */
.rbc-toolbar .rbc-btn-group button {
  color: white;
  font-weight: 400;
}
.rbc-toolbar .rbc-btn-group button:hover {
  background-color: #09283A;
}
.rbc-toolbar .rbc-toolbar-label {
  color: white;
}

.rbc-toolbar .rbc-btn-group .rbc-active {
  background-color: white;
  font-weight: 400;
  color: #09283A;
}
.rbc-toolbar .rbc-btn-group .rbc-active:hover {
  background-color: #09283A;
  font-weight: 400;
  color: white;
}


.rbc-calendar .rbc-month-view .rbc-row .rbc-header span {
  color: white;
}

.rbc-month-row .rbc-row-bg .rbc-off-range-bg {
  background: #09283A;
}

.rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell button {
  color: white;
  font-weight: 400;
}
.rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-row .rbc-header button {
  color: white;
  font-weight: 400;
}
.rbc-time-content,.rbc-agenda-view {
  color: white;
}
.rbc-calendar {
  font-family: SF UI Text;
}

.rbc-day-bg.rbc-today,.rbc-header.rbc-today,.rbc-day-slot.rbc-time-column.rbc-now.rbc-today {
  background-color: #003a70;
}

.sidebarhide #sidebar {
  display: none;
}
.sidebarhide .right-panel {
  margin-left: 0;
}
.sidebarhide .right-panel .right-panel-padding {
  padding: 1px;
}

/* Easy Email Editor */
.arco-layout-sider.arco-layout-sider-light {
  background: #031827;
}
.arco-card-bordered {
  border: 2px solid #003a70;
  border-radius: 8px;
}
.arco-card-grid .arco-card {
  background: #031827;
}
#easy-email-editor .easy-email-editor-tabWrapper {
  border-top: 1px solid #003a70;
}
#easy-email-editor,#easy-email-editor .easy-email-editor-tabWrapper {
  background: #031827;
}
#easy-email-editor .easy-email-editor-button {
  background: #003a70;
  color: #fff;
  border-radius: 5px;
}
#easy-email-editor .easy-email-editor-button[disabled]  {
  border-color: transparent;
}
.arco-collapse-item-header,.arco-collapse,.arco-collapse-item-active>.arco-collapse-item-header,.arco-collapse-item-content {
  background: #031827;
  color: #fff;
}
._blockItemContainer_1ajtj_16 {
  background: #003a70;
  color: #fff;
  border-radius: 8px;
}
._blockItemContainer_1ajtj_16 .arco-typography{
  color: #fff;
}
.arco-input,.arco-textarea,.arco-select .arco-select-view {
  color: #fff;
  border-radius: 5px;
}
.arco-input-group .arco-input {
  border-radius: 5px;
}
.arco-tree-node-title-text {
  color: #fff;
}
.arco-tree-node-title:hover {
  background: #003a70;
  color: #fff;
}
.arco-input:focus, .arco-input.arco-input-focus,.arco-textarea:focus, .arco-textarea.arco-textarea-focus {
  color: #003a70
}
.right-panel #BlockLayerManager .arco-tree-node-selected .arco-tree-node-title {
  background: #003a70;
}
.os-content {
  color: #fff;
}


.phone {
  border: 40px solid #000;
  border-width: 55px 7px;
  border-radius: 40px;
  margin: 20px auto;
  overflow: hidden;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
 -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 2s; /* Firefox < 16 */
      -ms-animation: fadein 2s; /* Internet Explorer */
       -o-animation: fadein 2s; /* Opera < 12.1 */
          animation: fadein 2s;
}

.phone .mobile_frame {
  border: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color:#000;
}

.template_frame {
  border: 0;
  width: 100%;
  height: 300px;
}
