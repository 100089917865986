.graph--duration--selector{
    border: 1px solid #3f4448;
    color: white;
    font-size: 15px;
    background: transparent;
    outline: none;
    padding: 7px 9px;
    margin: 0 12px;
    cursor: pointer;
}
.graph--duration--selector > option{
    color: black;
}