.overlay {
  background: linear-gradient(transparent, #031827);
  top: 0;
  height: 10%;
  rotate: 180deg;
  z-index: 2;
  left: 0;
  position: absolute;
  width: 100%;
}
.overlay-6 {
  background: linear-gradient(transparent, #020d15);
  top: 0;
  height: 100px;
  rotate: 180deg;
  z-index: 2;
  left: 0;
  position: absolute;
  width: 100%;
}
.overlay-1 {
  background: linear-gradient(to right, transparent, #031827);

  top: 0;
  width: 10%;
  rotate: 180deg;
  left: 0;
  position: absolute;
  height: 100%;
}
.overlay-2 {
  background: linear-gradient(to left, transparent, #031827);
  top: 0;
  width: 10%;
  rotate: 180deg;
  right: 0;
  position: absolute;
  height: 100%;
}
.overlay-3 {
  background: linear-gradient(transparent, #031827);
  bottom: 0;
  height: 50%;

  left: 0;
  position: absolute;
  width: 100%;
}
.overlay-5 {
  background: linear-gradient(transparent, #020d15);
  bottom: 0;
  height: 10%;

  left: 0;
  position: absolute;
  width: 100%;
}
